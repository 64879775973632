<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-progress">
        </div>
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{'GENERAL.APPROVAL_LIST' | translate}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="kt-portlet__body">
            <div class="col-12">
                <div class="form-group">
                    {{ data.displayText | translate }}
                    <br />
                    <b>
                        <mt-edit-request-value [inputType]="data.inputType" [value]="data.curValue">
                        </mt-edit-request-value>
                    </b>
                </div>
                <div class="form-group">
                    <label class="kt-font-bolder">
                        {{ 'GENERAL.APPROVAL_LIST' | translate }}
                    </label>
                    <div class="mat-table__wrapper">
                        <mat-table class="lmat-elevation-z8"
                                   #table
                                   [dataSource]="dataList.dataSource"
                                   matSort
                                   #sort1="matSort"
                                   matSortActive="createdDate"
                                   matSortDirection="desc"
                                   matSortDisableClear>
                            <!-- Checkbox Column -->
                            <ng-container matColumnDef="createdDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.CreatedDate'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    <a (click)="this.showRequestDetails(request)" class="mt-link">{{request.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}</a>

                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="approvedBy">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.ApprovedBy'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    {{  request.approvedByUser }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="editRequestStatus">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.Status'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    <span class="kt-badge kt-badge--inline kt-badge--rounded kt-badge--{{ getItemCssClassByStatus(request.editRequestStatus) }}">{{  getStatusLabel(request.editRequestStatus) }}</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="module">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.Module'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    {{getModuleLabel(request.module, request.subModule) }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fieldname">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.FieldName'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    {{ getDislayFieldName(request.displayFieldName, request.hasTranslate)  }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="oldvalue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.OldValue'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    <mt-edit-request-value [inputType]="request.fieldType" [value]="request.oldValue">
                                    </mt-edit-request-value>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="newvalue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.NewValue'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    <mt-edit-request-value [inputType]="request.fieldType" [value]="request.newValue">
                                    </mt-edit-request-value>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="notes">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.RequestNotes'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    {{  request.notes }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="approvedNotes">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'NAME_OF_ACTIONLOG.ApprovalNotes'|translate}}</mat-header-cell>
                                <mat-cell *matCellDef="let request">
                                    {{  request.approvedNotes }}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                        </mat-table>

                        <div class="mat-table__message" *ngIf="!dataList.hasItems && !(dataList.isPreloadTextViewed$ | async)">{{'GENERAL.NO_RECORDS_FOUND'|translate}}</div>
                        <div class="mat-table__message" *ngIf="dataList.isPreloadTextViewed$ | async">{{'GENERAL.PLEASE_WAIT'|translate}}</div>
                    </div>

                    <!-- start: BOTTOM -->
                    <div class="mat-table__bottom">
                        <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
                        <mat-spinner [diameter]="20" *ngIf="dataList.loading$ | async"></mat-spinner>
                        <!-- MATERIAL PAGINATOR | Binded to dasources -->
                        <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
                        <mat-paginator [pageSize]="listPageSize" [pageSizeOptions]="listPageSizeOptions" [length]="dataList.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
                    </div>
                    <!-- end: BOTTOM -->
                </div>

            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <button mat-button (click)="onCancelClick()">
                    {{ 'GENERAL.CLOSE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="kt-portlet">
    <!--[ngClass]="{ 'kt-portlet--body-progress' : viewLoading, 'kt-portlet--body-progress-overlay' : loadingAfterSubmit }"-->
    <div class="kt-portlet__head kt-portlet__head__custom" *ngIf="object">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title"> {{ 'SETTING.NOTIFICATION.TITLE' | translate }}</h3>
        </div>
    </div>
    <div *ngIf="object">
        <div class="kt-form">
            <div class="kt-portlet__body">

                <div class="kt-portlet__body-progress">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>

                <div class="form-group kt-form__group row d-block">
                    <div class="col-lg-4 kt-margin-bottom-20-mobile">

                        {{object.notes}}

                    </div>
                </div>

            </div>
            <div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
                <div class="kt-form__actions kt-form__actions--solid">
                    <div class="row text-right">
                        <div class="col-lg-12">
                            <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="{{'GENERAL.CLOSE_BUTTON' | translate }}">
                                {{ 'GENERAL.CLOSE_BUTTON' | translate }}
                            </button>&nbsp;

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

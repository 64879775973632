<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-progress">
        </div>
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{'NAME_OF_ACTIONLOG.EditRequest' | translate}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="kt-portlet__body">
            <div class="col-12">
                <div class="form-group">
                    <span class="kt-badge kt-badge--inline kt-badge--rounded kt-badge--{{ getItemCssClassByStatus(request.editRequestStatus) }}">{{  getStatusLabel(request.editRequestStatus) }}</span>
                </div>
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.FieldName' | translate }}:
                    </b>
                    <br />
                    {{ request.displayFieldName | translate }}
                    <br />
                </div>
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.NewValue' | translate }}:
                    </b>
                    <br />
                    {{ request.newValue }}
                    <br />
                </div>
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.OldValue' | translate }}:
                    </b>
                    <br />
                    <mt-edit-request-value [inputType]="request.fieldType" [value]="request.oldValue">
                    </mt-edit-request-value>
                </div>
                <hr />
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.CreatedDate' | translate }}:
                    </b>
                    {{request.createdDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                </div>
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.CreatedBy' | translate }}:
                    </b>
                    {{  request.createdByUser }}
                </div>
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.RequestNotes' | translate }}:
                    </b>
                    {{ request.notes }}
                </div>
                <div *ngIf="request.editRequestStatus != 0">
                    <div class="form-group">
                        <b>
                            {{ 'NAME_OF_ACTIONLOG.ApprovedDate' | translate }}:
                        </b>
                        {{request.approvedDate | date: 'dd/MM/yyyy HH:mm:ss'}}
                    </div>
                    <div class="form-group">
                        <b>
                            {{ 'NAME_OF_ACTIONLOG.ApprovedBy' | translate }}:
                        </b>
                        {{  request.approvedByUser }}
                    </div>
                    <div class="form-group">
                        <b>
                            {{ 'NAME_OF_ACTIONLOG.ApprovalNotes' | translate }}:
                        </b>
                        {{ request.approvedNotes }}
                    </div>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <button mat-button (click)="onCancelClick()">
                    {{ 'GENERAL.CLOSE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!--begin::Body-->
<div class="kt-login__body">
	<!--begin::Signin-->
	<div class="kt-login__form">
		<div class="kt-login__logo">
			<img alt="logo" src="./assets/media/logos/logo_oxalis_dark.png">
		</div>
		<div class="kt-login__title">
			<h3>{{ 'AUTH.REGISTER.TITLE' | translate }}</h3>
		</div>
		<kt-auth-notice></kt-auth-notice>
		<form class="kt-form kt-reg-form" [formGroup]="registerForm" (keydown.enter)="submit()" autocomplete="off">
			<div class="form-group">
				<mat-form-field class="mat-form-field-fluid" appearance="outline">
					<mat-label>{{ 'AUTH.INPUT.FULLNAME' | translate }}</mat-label>
					<input matInput type="text" formControlName="fullname" />
					<mat-error *ngIf="isControlHasError('fullname','required')">
						{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}
					</mat-error>
					<mat-error *ngIf="isControlHasError('fullname','minlength')">
						{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3
					</mat-error>
					<mat-error *ngIf="isControlHasError('fullname','maxlength')">
						{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100
					</mat-error>
					<mat-error *ngIf="isControlHasError('fullname','serverError')">
						{{ 'AUTH.REGISTER.FAILED' | translate }}
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field class="mat-form-field-fluid" appearance="outline">
					<mat-label>{{ 'AUTH.INPUT.EMAIL_PLACEHOLDER' | translate }}</mat-label>
					<input matInput type="email" formControlName="email" autocomplete="on" />
					<mat-error *ngIf="isControlHasError('email','required')">
						{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						{{ 'AUTH.VALIDATION.EMAIL_INVALID' | translate }}
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','serverError')">
						{{ 'AUTH.REGISTER.FAILED' | translate }}
					</mat-error>
				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="kt-login__actions kt-regis__actions">
				<a href="javascript:;" routerLink="/auth/login" class="kt-login__signup-link">{{ 'AUTH.LOGIN.BUTTON' | translate }}</a>
				<button (click)="submit()" id="kt_login_signin_submit" class="btn btn-primary kt-login__btn-medium" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</button>
			</div>
			<!--end::Action-->
		</form>
	</div>
</div>

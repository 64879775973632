<div ngbDropdown [placement]="placement" class="dropdown" style="max-height: 800px; font-weight:normal" (openChange)="dropdownOpenChange($event)">
    <a ngbDropdownToggle href="javascript:;" class="mat-icon-button--label dropdown-toggle  {{ colorStyle }}" style="padding:7px;">
        <ng-container>
            <mat-icon *ngIf="iconPos=='left'">{{ icon }}</mat-icon>
            <span [innerHTML]="getSelectedLabel()"></span>
            <mat-icon *ngIf="iconPos=='right'">{{ icon }}</mat-icon>
        </ng-container>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
        <div class="m-3">
            <div *ngFor="let option of optionList">
                <label class="kt-radio ">
                    <input type="radio" [(ngModel)]="selectedOption" [value]="option.Value"
                           (change)="onOptionChange(option.Value)">
                    {{ option.Text }}
                    <span></span>
                </label>
            </div>
            <div *ngIf="selectedOption == '0'">
                <div class="row text-center">
                    <input type="text"
                           class="form-control text-center"
                           [(ngModel)]="fromTime"
                           [matAutocomplete]="autoFromTime"
                           (change)="changeTime($event);"
                           style="border: 1px solid #d3d3d9; width: 80px; margin-right:4px">
                    <mat-autocomplete #autoFromTime="matAutocomplete"
                                      (optionSelected)="changeTime($event);">
                        <mat-option *ngFor="let option of fromTimeList" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                    <input type="text"
                           class="form-control text-center"
                           [(ngModel)]="toTime"
                           [matAutocomplete]="autoToTime"
                           (change)="changeTime($event);"
                           style="border: 1px solid #d3d3d9; width: 80px;">
                    <mat-autocomplete #autoToTime="matAutocomplete"
                                      (optionSelected)="changeTime($event);">
                        <mat-option *ngFor="let option of toTimeList" [value]="option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <!--<input type="text" [(ngModel)]="mtModel" [selectMode]="'range'" class="form-control" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"
                       (dateTimeInput)="dateTimeInput()"
                       style="border: 1px solid #d3d3d9;">
                <owl-date-time [pickerType]="'timer'" #dt2></owl-date-time>-->
                <!--
                    <div>
                                <input [(ngModel)]="mtModel" [selectMode]="'range'" [owlDateTime]="dpDaterange1" class="iptOwlDateTime"
                                       (dateTimeInput)="dateTimeInput()">
                <owl-date-time [pickerType]="'calendar'" #dpDaterange1></owl-date-time>
                            </div>-->
            </div>
        </div>

        <!--<footer>
            <a href="javascript:;" class="kt-link text-danger" (click)="cancelFilter()" [hidden]="hideCancelFilter">
                {{ 'GENERAL.CANCEL_FILTER' | translate }}
            </a>
            <a href="javascript:;" class="kt-link text-body" (click)="dropdown.toggle()" [hidden]="hideCancel">
                {{ 'GENERAL.CANCEL' | translate }}
            </a>
            <button mat-stroked-button color="primary" (click)="applyFilter()">
                <span>{{ 'GENERAL.APPLY' | translate }}</span>
            </button>
        </footer>-->
    </div>
</div>

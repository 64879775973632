<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-progress">
        </div>
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{'NAME_OF_ACTIONLOG.PARTICIPANT' | translate}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="kt-portlet__body">
            <div class="col-12" *ngIf="refPrices">
                <div class="mb-3">
                    {{ 'NAME_OF_ACTIONLOG.UsedQuantity' | translate }} = {{ refPrices.parentObjectPrice.quantityUsed }}
                </div>
                <table class="price-table">
                    <thead>
                        <tr>
                            <th class="price-table-header" *ngIf="refPrices.parentObjectPrice.groupPriceType == groupPriceType.Range">{{ 'NAME_OF_ACTIONLOG.MinValue' | translate }}</th>
                            <th class="price-table-header" *ngIf="refPrices.parentObjectPrice.groupPriceType == groupPriceType.Range">{{ 'NAME_OF_ACTIONLOG.MaxValue' | translate }}</th>
                            <th scope="col" class="price-table-header text-right" *ngFor="let currency of currencyList">
                                {{currency.currencyCode}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let priceItem of refPrices.groupList; let k=index">
                            <td scope="row" class="price-table-cell price-table-cell-text" *ngIf="refPrices.parentObjectPrice.groupPriceType == groupPriceType.Range">
                                <span class="text-right">
                                    {{priceItem.mainObjectPrice.minQuantity | number}}
                                </span>
                            </td>
                            <td scope="row" class="price-table-cell price-table-cell-text text-right" *ngIf="refPrices.parentObjectPrice.groupPriceType == groupPriceType.Range">
                                <span class="text-right">
                                    {{priceItem.mainObjectPrice.maxQuantity == 0 ? '∞' : (priceItem.mainObjectPrice.maxQuantity  | number) }}
                                </span>
                            </td>
                            <td class="price-table-cell price-table-cell-text text-right" *ngFor="let price of priceItem.prices">
                                <span class="text-right">
                                    {{price.price | number}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <button mat-button (click)="onCancelClick()">
                    {{ 'GENERAL.CLOSE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

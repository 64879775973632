import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mt-filter-dropdown',
    templateUrl: './mt-filter-dropdown.component.html',
    styleUrls: ['./mt-filter-dropdown.component.scss']
})

export class MtFilterDropdownComponent {
    @ViewChild('selectionList', { static: true }) selectionList: MatSelectionList;
    @ViewChild(NgbDropdown)
    dropdown: NgbDropdown;

    @Input() mtModel: any[];
    @Input() icon: string = 'keyboard_arrow_down';
    @Input() iconPos: string = 'right';
    @Input() label: string = '';
    @Input() colorStyle: string = '';
    @Input() placement: string = 'bottom-left';
    @Input() multiple: boolean = true;
    @Input() labelToggle: boolean = false;
    @Input() hideCancelFilter: boolean = true;
    @Input() hideCancel: boolean = false;
    @Input() hideSelectAll: boolean = true;


    @Output() change = new EventEmitter<any[]>();

    selectedValues = [];
    focusValue = [];

    constructor(protected translate: TranslateService, private cdr: ChangeDetectorRef) {
    }

    applyFilter() {
        this.selectedValues = [];
        if (this.focusValue.length > 0) {
            this.focusValue.forEach(value => {
                this.selectedValues.push(value);
            });
        }
        this.change.emit(this.selectedValues);
        this.dropdown.toggle();
    }

    cancelFilter() {
        this.focusValue = [];
        this.selectedValues = [];
        this.change.emit(this.selectedValues);
        this.dropdown.toggle();
    }

    getSelectedLabel() {
        if (this.labelToggle) {
            if (this.selectedValues.length > 0) {
                const selectedText = [];
                this.selectedValues.forEach(m => {
                    const result = this.mtModel.filter(val => val.Value === m);
                    if (result[0] && selectedText.length <= 5) {
                        if (selectedText.length == 5) {
                            selectedText.push('...');
                        } else {
                            selectedText.push(result[0].Text);
                        }
                    }
                });

                var countChar = selectedText.join('').split('').length;

                if (countChar <= 25)
                    return `${this.label}: <font color="#017C38">${selectedText.join(', ')}</font>`;
                else
                    return `${this.label}: <font color="#017C38">${this.translate.instant("GENERAL.SELECTED")} ${this.selectedValues.length}</font>`;
            } else {
                if (!this.multiple) {
                    this.selectedValues.push(-1);
                    this.focusValue.push(-1);
                }
                return `${this.label}: <font color="#017C38">${this.translate.instant("GENERAL.ALL")}</font>`;
            }
        }
        return this.label;
    }

    dropdownOpenChange(event) {
        if (event) {
            this.focusValue = Object.assign([], this.selectedValues);
        }
    }

    onClickOption(option) {
        if (this.multiple) {
            if (this.focusValue.filter(item => item === option).length > 0) {
                this.focusValue = this.focusValue.filter(item => item !== option);
            } else {
                var previous = Object.assign([], this.focusValue);
                previous.push(option);
                this.focusValue = Object.assign([], previous);
            }
        } else {
            this.focusValue[0] = option;
        }
    }
    unSelectAll() {
        this.focusValue = [];
    }
    selectAll() {
        this.focusValue = [];
        this.mtModel.forEach(item => {
            this.focusValue.push(item.Value);
        });
        //this.cdr.detectChanges();
    }
    //public refreshList() {
    //    //this.mtModel.push({ Value: 100000, Text: "Refresh" });
    //    //this.mtModel.reduceRight()
    //    this.cdr.detectChanges();
    //}
}

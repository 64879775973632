import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mt-select-dropdown',
    templateUrl: './mt-select-dropdown.component.html',
    styleUrls: ['./mt-select-dropdown.component.scss']
})

export class MtSelectDropdownComponent {
    @ViewChild(NgbDropdown)
    private dropdown: NgbDropdown;

    @Input() mtModel: any[];
    @Input() icon: string = 'keyboard_arrow_down';
    @Input() iconPos: string = 'right';
    @Input() label: string = '';
    @Input() colorStyle: string = '';
    @Input() placement: string = 'bottom-left';
    @Input() multiple: boolean = true;
    @Input() labelToggle: boolean = false;
    @Input() selectedObjs: any[];

    @Output() change = new EventEmitter<any[]>();
    @Output() loadModel = new EventEmitter<boolean>();

    selectedValues = [];
    focusValue = [];
    waitingMsg = this.translate.instant("GENERAL.PLEASE_WAIT");

    constructor(protected translate: TranslateService) {
        
    }

    getSelectedLabel() {
        if (this.selectedValues.length > 0) {
            const selectedText = [];
            this.selectedValues.forEach(m => {
                const result = this.mtModel.filter(val => val.Value === m);
                if (result)
                    selectedText.push(result[0].Label);
            });
            if (this.labelToggle)
                return `${this.label}: ${selectedText.join(', ')}`;

            return `${selectedText.join(', ')}`;
        }
        return this.label;
    }

    dropdownOpenChange(event) {
        if (event) {
            if (this.mtModel.length == 0) {
                this.loadModel.emit(true);
            } else {
                this.focusValue = this.selectedValues;
            }
            if (this.selectedValues.length == 0 && this.selectedObjs.length > 0) {
                this.focusValue = this.selectedObjs;
                this.selectedValues = this.selectedObjs;
            }
        }
    }

    onItemClick(val) {
        this.selectedValues = [];
        this.selectedValues.push(val);
        this.change.emit(this.selectedValues);
        this.dropdown.toggle();
    }

    clearFilter() {
        if (this.focusValue.length == 0) {
            if (this.selectedValues.length == 0 && this.selectedObjs.length > 0) {
                this.focusValue = this.selectedObjs;
            } else {
                this.focusValue = this.selectedValues;
            }
        }
    }
}

<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-progress">
        </div>
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ (data.isApprove ? 'NAME_OF_ACTIONLOG.Approve' : 'NAME_OF_ACTIONLOG.Reject' ) | translate}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="kt-portlet__body">
            <div class="col-12">
                <div *ngIf="model.editRequests.length == 1">
                    <div class="form-group">
                        {{ request.displayFieldName | translate}}
                    </div>
                    <div class="form-group">
                        <b>
                            {{ 'NAME_OF_ACTIONLOG.OldValue' | translate }}:
                        </b>
                        <br />
                        <mt-edit-request-value [inputType]="request.fieldType" [value]="request.oldValue">
                        </mt-edit-request-value>
                    </div>
                    <div class="form-group">
                        <b>
                            {{ 'NAME_OF_ACTIONLOG.NewValue' | translate }}:
                        </b>
                        <br />
                        <mt-edit-request-value [inputType]="request.fieldType" [value]="request.newValue">
                        </mt-edit-request-value>
                    </div>
                    <div class="form-group">
                        <b>
                            {{ 'NAME_OF_ACTIONLOG.RequestNotes' | translate }}:
                        </b>
                        <br />
                        {{ request.notes }}
                    </div>
                </div>
                <div class="form-group">
                    <b>
                        {{ 'NAME_OF_ACTIONLOG.ApprovalNotes' | translate }} <span *ngIf="!data.isApprove">({{'GENERAL.MANDATORY' | translate}})</span>:
                    </b>
                    <input type="email" class="form-control" [formControl]="notes">
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <button mat-button (click)="onCancelClick()" class="kt-pull-left">
                    {{ 'GENERAL.CLOSE_BUTTON' | translate }}
                </button>
                <button mat-raised-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="(notes.value.length == 0 && !data.isApprove)">
                    {{ 'GENERAL.SAVE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<a href="javascript:;" class="mat-icon-button--label" style="padding:7px!important;">
    <input [(ngModel)]="mtModel" [selectMode]="'range'" [owlDateTime]="dpDaterange" class="iptOwlDateTime"
           (dateTimeInput)="dateTimeInput()">
    <ng-container>
        <div [owlDateTimeTrigger]="dpDaterange" style="display:contents;">
            <mat-icon *ngIf="iconPos=='left'">{{ icon }}</mat-icon>
            <span [innerHTML]="getSelectedDateLabel()"></span>
            <mat-icon *ngIf="iconPos=='right'">{{ icon }}</mat-icon>
        </div>
    </ng-container>
    <ng-container *ngIf="showClearBtn && mtModel[0] != ''">
        <mat-icon (click)="clearInput()">clear</mat-icon>
    </ng-container>
</a>
<owl-date-time [pickerType]="'calendar'" #dpDaterange></owl-date-time>

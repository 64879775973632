<div class="kt-grid__item kt-grid__item--fluid">
    <div class="mt-help">
        <kt-portlet>
            <kt-portlet-header [icon]="'material-icons kt-font-brand'" [matIcon]="'support'" [title]="translate.instant('GENERAL.CONTEXT_HELP')"></kt-portlet-header>
            <kt-portlet-body>
                <strong>{{ getParentLabel() }}</strong>
                <div *ngIf="parentHelpContent.length > 0">
                    <div *ngIf="!parentEditMode" [innerHTML]="parentHelpContent"></div>
                    <ng-template [ngxPermissionsOnly]="['CanEditContextHelp']">
                            <div *ngIf="!parentEditMode" class="mt-form-group-right">
                                <button mat-icon-button class="mat-icon-button--sm" color="grey" (click)="toggleEditMode('parent')">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                            <div [hidden]="!parentEditMode" class="kt-form__group">
                                <div class="col-md-12">
                                    <div class="row">
                                        <ckeditor #parentContentInput [editor]="ckEditor" [data]="parentHelpContent" [config]="{ toolbar: [ 'heading', 'bulletedList', 'numberedList', 'bold', 'italic', 'undo', 'redo'] }" style="width:100%"></ckeditor>
                                    </div>
                                    <div class="mt-form-group-right row mt-help--actions">
                                        <button mat-icon-button class="mat-icon-button--sm" color="grey" (click)="saveParentHelpContent()"><mat-icon>done</mat-icon></button>
                                        <button mat-icon-button class="mat-icon-button--sm" color="grey" (click)="cancelHelpContent('parent')"><mat-icon>clear</mat-icon></button>
                                    </div>
                                </div>
                            </div>
                    </ng-template>
                </div>
                <mat-divider *ngIf="ctrHelpContent.length > 0 && parentHelpContent.length > 0"></mat-divider>
                <strong>{{ getControlLabel() }}</strong>
                <div *ngIf="ctrHelpContent.length > 0">
                    <div *ngIf="!ctrEditMode" [innerHTML]="ctrHelpContent"></div>
                    <ng-template [ngxPermissionsOnly]="['CanEditContextHelp']">
                        <div *ngIf="!ctrEditMode" class="mt-form-group-right">
                            <button mat-icon-button class="mat-icon-button--sm" color="grey" (click)="toggleEditMode('control')">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                        <div [hidden]="!ctrEditMode" class="kt-form__group">
                            <div class="col-md-12">
                                <div class="row">
                                    <ckeditor #contentInput [editor]="ckEditor" [data]="ctrHelpContent" [config]="{ toolbar: [ 'heading', 'bulletedList', 'numberedList', 'bold', 'italic', 'undo', 'redo'] }" style="width:100%"></ckeditor>
                                </div>
                                <div class="mt-form-group-right row mt-help--actions">
                                    <button mat-icon-button class="mat-icon-button--sm" color="grey" (click)="saveCtrHelpContent()"><mat-icon>done</mat-icon></button>
                                    <button mat-icon-button class="mat-icon-button--sm" color="grey" (click)="cancelHelpContent('control')"><mat-icon>clear</mat-icon></button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
            </kt-portlet-body>
        </kt-portlet>
    </div>
</div>

<div ngbDropdown [placement]="placement" class="dropdown" (openChange)="dropdownOpenChange($event)" style="max-height: 800px; ">
    <a ngbDropdownToggle href="javascript:;" class="mat-icon-button--label dropdown-toggle  {{ colorStyle }}" style="padding:7px;">
        <ng-container>
            <mat-icon *ngIf="iconPos=='left'">{{ icon }}</mat-icon>
            <span [innerHTML]="getSelectedLabel()"></span>
            <mat-icon *ngIf="iconPos=='right'">{{ icon }}</mat-icon>
        </ng-container>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">

        <div class="kt-input-icon kt-input-icon--right">
            <input matInput #filterText type="text" style="box-sizing:border-box;"
                   class="form-control" placeholder="{{ 'GENERAL.SEARCH' | translate }}" autocomplete="off">
            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                <span *ngIf="filterText.value.length==0" class="material-icons">search</span>
                <span *ngIf="filterText.value.length>0" class="material-icons" (click)="filterText.value=''" style="cursor: pointer;">clear</span>
            </span>
        </div>

        <div *ngIf="focusValue.length>0" class="kt-padding-l-16 kt-padding-b-8" [hidden]="hideSelectAll">
            <span class="kt-align-right">({{focusValue.length}})</span>
            <a href="javascript:;" class="kt-link" (click)="unSelectAll()">
                {{ 'GENERAL.UNSELECT_ALL' | translate }}
            </a>
        </div>

        <div *ngIf="focusValue.length<1" class="kt-padding-l-16 kt-padding-b-8">
            <a href="javascript:;" class="kt-link" (click)="selectAll()" [hidden]="hideSelectAll">
                {{ 'GENERAL.SELECT_ALL' | translate }}
            </a>
        </div>

        <mat-selection-list #selectionList [class]="'kt-nav' + (multiple ? '' : ' single-select')" style="max-height:300px; overflow:auto;"
                            [multiple]="multiple" [ngModel]="focusValue">
            <mat-list-option [class]="filter.Class?filter.Class:''" checkboxPosition="before" [value]="filter.Value"
                             (click)="onClickOption(filter.Value)"
                             *ngFor="let filter of (mtModel | filterText:filterText.value)">
                <span [class]="filter.Class?filter.Class:''">{{ filter.Text }}</span>
            </mat-list-option>
        </mat-selection-list>

        <footer>
            <a href="javascript:;" class="kt-link text-danger" (click)="cancelFilter()" [hidden]="hideCancelFilter">
                {{ 'GENERAL.CANCEL_FILTER' | translate }}
            </a>
            <a href="javascript:;" class="kt-link text-body" (click)="dropdown.toggle()" [hidden]="hideCancel">
                {{ 'GENERAL.CANCEL' | translate }}
            </a>
            <button mat-stroked-button color="primary" (click)="applyFilter()">
                <span>{{ 'GENERAL.APPLY' | translate }}</span>
            </button>
        </footer>
    </div>
</div>

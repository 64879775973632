<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-progress">
            <!-- here can place a progress bar-->
            <!--<mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>-->
        </div>
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{'PERMISSION.REQUESTEDIT' | translate}}</h3>
            <span class="kt-portlet__head-icon kt-hide">
                <i class="la la-gear"></i>
            </span>
        </div>
    </div>
    <div class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed">
        <div class="kt-portlet__body">
            <div class="col-12">
                <div class="form-group">
                    {{ data.displayText | translate}}
                    <br />
                    <b>
                        <mt-edit-request-value [inputType]="data.inputType" [value]="data.curValue">
                        </mt-edit-request-value>
                    </b>
                </div>
                <hr />
                <div class="form-group">
                    <ng-container [ngSwitch]="data.inputType">
                        {{ 'GENERAL.NEW_VALUE' | translate }}
                        <div *ngSwitchCase="editRequestType.Email" class="form-group">
                            <input type="email" class="form-control" [formControl]="newValue">
                        </div>
                        <div *ngSwitchCase="editRequestType.MultyLineText" class="form-group">
                            <textarea rows="3" class="form-control" [formControl]="newValue"></textarea>
                        </div>
                        <div *ngSwitchCase="editRequestType.Radio" class="form-group">
                            <div *ngFor="let item of getListArray(data.listArray)">
                                <label class="kt-radio">
                                    <input type="radio" [formControl]="newValue" [value]="item">{{ item }}
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div *ngSwitchCase="editRequestType.List" class="form-group">
                            <select class="form-control custom-select" [formControl]="newValue">
                                <option *ngFor="let item of getListArray(data.listArray)" [value]="item">
                                    {{ item }}
                                </option>
                            </select>
                        </div>
                        <div *ngSwitchCase="editRequestType.PaymentType" class="form-group">
                            <select class="form-control custom-select" [formControl]="newValue">
                                <option *ngFor="let type of paymentTypeList" [value]="type.Value">
                                    {{ type.Text }}
                                </option>
                            </select>
                        </div>
                        <div *ngSwitchCase="editRequestType.CheckBox" class="form-group">
                            <div class="kt-checkbox-single">
                                <label class="kt-checkbox">
                                    <input type="checkbox" [formControl]="newValue">
                                    <span></span>
                                </label>
                            </div>
                        </div>
                        <div *ngSwitchCase="editRequestType.PaymentDate" class="form-group">
                            <input [owlDateTimeTrigger]="editPicker" [owlDateTime]="editPicker" type="text" class="form-control" [formControl]="newValue">
                            <owl-date-time #editPicker></owl-date-time>
                        </div>
                        <div *ngSwitchCase="editRequestType.DateTime" class="form-group">
                            <input [owlDateTimeTrigger]="editPicker" [owlDateTime]="editPicker" type="text" class="form-control" [formControl]="newValue">
                            <owl-date-time #editPicker></owl-date-time>
                        </div>
                        <div *ngSwitchCase="editRequestType.Date" class="form-group">
                            <div class="kt-input-icon kt-input-icon--right">
                                <input [owlDateTimeTrigger]="datePicker" [owlDateTime]="datePicker" type="text" class="form-control" [formControl]="newValue">
                                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span class="material-icons">calendar_today</span>
                                </span>
                                <owl-date-time [pickerType]="'calendar'" #datePicker></owl-date-time>
                            </div>
                        </div>
                        <div *ngSwitchCase="editRequestType.Time" class="form-group">
                            <div class="kt-input-icon kt-input-icon--right">
                                <input [owlDateTimeTrigger]="timePicker" [owlDateTime]="timePicker" type="text" class="form-control" [formControl]="newValue">
                                <owl-date-time [pickerType]="'timer'" #timePicker></owl-date-time>
                            </div>
                        </div>
                        <div *ngSwitchCase="editRequestType.Number" class="form-group">
                            <input [formControl]="newValue"
                                   class="form-control kt-font-brand text-right"
                                   mask="separator"
                                   thousandSeparator="{{'GENERAL.THOUSAND_SEPARATOR'|translate}}">
                        </div>
                        <div *ngSwitchCase="editRequestType.Money" class="form-group">
                            <input [formControl]="newValue"
                                   class="form-control kt-font-brand text-right"
                                   mask="separator"
                                   thousandSeparator="{{'GENERAL.THOUSAND_SEPARATOR'|translate}}">
                        </div>
                        <div *ngSwitchDefault>
                            <div class="form-group">
                                <input type="text" class="form-control" [formControl]="newValue">
                            </div>
                        </div>
                        <div class="form-group">
                            {{ 'NAME_OF_ACTIONLOG.RequestNotes' | translate }}
                            <input type="text" class="form-control" [formControl]="reasonNote">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
            <div class="kt-form__actions kt-form__actions--sm">
                <button mat-button (click)="onCancelClick()" class="kt-pull-left">
                    {{ 'GENERAL.CLOSE_BUTTON' | translate }}
                </button>
                <button mat-raised-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="newValue.value == data.curValue">
                    {{ 'GENERAL.SAVE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="kt-portlet" data-html2canvas-ignore="true">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{getTitle()}}</h3>
        </div>
    </div>
    <div class="kt-form">
        <div class="kt-portlet__body">

            <div class="kt-portlet__body-progress">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>

            <!--<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                { { getErrorMsg() } }
            </kt-alert>-->

            <div class="form-group kt-form__group row d-block">
                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                    <div class="form-group">
                        <label>{{'FRONTPAGE.FEEDBACK_NOTE'|translate}}</label>
                        <textarea rows="6" class="form-control"
                                  [(ngModel)]="feedbackContent"
                                  placeholder="{{'FRONTPAGE.FEEDBACK_NOTE'|translate}}"
                                  (focus)="showHelpContext('[service][notes]')"></textarea>
                    </div>
                    <div>
                        <mat-checkbox [color]="'primary'" [hidden]="previewUrl == ''" [(ngModel)]="isSaveImg">
                            <span> {{'FRONTPAGE.SEND_SCREENSHOT'|translate}}</span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="form-group kt-form__group">
                <img style="max-width:100%;" (click)="viewImg()" id="myImg" [src]="previewUrl" />
            </div>
            <div class="please-wait">
                <div class="mat-table__message" [hidden]="previewUrl != ''" style="text-align:center;">
                    <mat-spinner [diameter]="20" style="margin: 0 auto;" [hidden]="previewUrl != ''"></mat-spinner>{{'GENERAL.PLEASE_WAIT_IMAGE'|translate}}
                </div>
            </div>
            <!-- The Modal -->
            <div id="myModal" class="modal">
                <span class="close" (click)="closeModal()">&times;</span>
                <img class="modal-content" [src]="previewUrl" id="img01">
                <div id="caption"></div>
            </div>

        </div>
        <div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
            <div class="kt-form__actions kt-form__actions--solid">
                <div class="row text-right">
                    <div class="col-lg-12">
                        <button type="button" mat-raised-button [mat-dialog-close]="close == true" (click)="onClose()" cdkFocusInitial matTooltip="{{ 'GENERAL.CANCEL_BUTTON' | translate }}">
                            {{ 'GENERAL.CANCEL' | translate }}
                        </button>&nbsp;
                        <button type="button" [disabled]="!(feedbackContent.length > 0)" mat-raised-button color="primary" (click)="onDialogSubmit()" matTooltip="{{ 'GENERAL.SAVE_BUTTON' | translate }}" >
                            {{ 'FRONTPAGE.FEEDBACK_SEND' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

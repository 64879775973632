<div ngbDropdown placement="bottom" autoClose="outside" class="kt-header__topbar-item">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon" [ngClass]="{'kt-pulse kt-pulse--brand': pulse, 'kt-pulse--light' : pulseLight, 'kt-header__topbar-icon--success' : iconType === 'success'	}">
			<span class="material-icons">
				notifications
			</span>
			<!--<i *ngIf="!useSVG" [ngClass]="icon"></i>-->
			<!--<span *ngIf="useSVG" class="kt-svg-icon" [ngClass]="{'kt-svg-icon--success' : iconType === 'success'}" [inlineSVG]="icon"></span>-->
			<span class="kt-pulse__ring" [hidden]="!pulse"></span>
		</span>
		<!--<span class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--noti" [hidden]="!dot"></span>-->
		<span class="kt-badge kt-badge--custom kt-badge--notify kt-badge--xl kt-badge--noti" [hidden]="unreadNotificationCounter<1">{{unreadNotificationCounter}} </span>
	</div>

	<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
		<form>
			<!--begin: Head -->
			<div class="kt-head kt-head--skin-{{skin}} kt-head--fit-x kt-head--fit-b" [ngStyle]="{'background-image': backGroundStyle() }">
				<h3 class="kt-head__title">
					{{ 'SETTING.NOTIFICATION.TITLE' | translate }}&nbsp;
					<span *ngIf="unreadNotificationCounter>0" class="btn btn-{{ type }}  btn-sm btn-bold btn-font-md">{{unreadNotificationCounter}} {{ 'SETTING.NOTIFICATION.NEW' | translate }}</span>
				</h3>
				<ul ktTabClickEvent class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-{{ type }} kt-notification-item-padding-x" role="tablist">
					<li class="nav-item">
						<a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab" href="javascript:;" role="tab" aria-selected="true">{{ 'SETTING.NOTIFICATION.ALERT' | translate }}</a>
					</li>
					
					<li class="nav-item">
						<a (click)="tab.select('tab-id-3')" class="nav-link" data-toggle="tab" href="javascript:;" role="tab" aria-selected="false">{{ 'SETTING.NOTIFICATION.LOG' | translate }}</a>
					</li>
				</ul>
			</div>
			<!--end: Head -->

			<ngb-tabset #tab="ngbTabset">
				<ngb-tab id="tab-id-1">

					<ng-template ngbTabContent>
						<div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '40vh', 'position': 'relative'}" class="kt-notification kt-margin-t-10 kt-margin-b-10">
						
						<a *ngFor="let notification of dataList.dataSource.data"  style=" cursor: pointer;"  (click)="viewNotification(notification.notificationId)" class="kt-notification__item {{(notification.notificationStatus==1)?'kt-notification__item--read':''}}">

							<div class="kt-notification__item-icon">
								<i class="flaticon2-notification kt-font-success"></i>
							</div>
							<div class="kt-notification__item-details">
								<div class="kt-notification__item-title">
									{{ notification.notes }}
								</div>
								<div class="kt-notification__item-time">
									{{notification.createdDate | date: 'dd/MM/yyyy HH:mm'}}
								</div>
							</div>
						</a>

						<div class="ps__rail-x" style="left: 0px; bottom: 0px;">
							<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
						</div>
						<div class="ps__rail-y" style="top: 0px; right: 0px;">
							<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
						</div>
</div>
					</ng-template>

				</ngb-tab>
				<ngb-tab id="tab-id-2">
					<ng-template ngbTabContent>
						<div class="kt-grid kt-grid--ver" style="min-height: 200px;">
							<div class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
								<div class="kt-grid__item kt-grid__item--middle kt-align-center">
									{{ 'SETTING.NOTIFICATION.NO_NOTIFICATION' | translate }}
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset>
		</form>
	</div>
</div>

<kt-portlet>
    <!--<kt-portlet-header [title]="'Roles list'" [class]="'kt-portlet__head--lg'" [viewLoading$]="dataSource.loading$">
        <ng-container ktPortletTools>
            <button (click)="addRole()" mat-raised-button matTooltip="{{ 'USER_MANAGEMENT.ROLE.NEW_ROLE_BUTTON' | translate }}" color="primary" type="button">
                <span>{{ 'USER_MANAGEMENT.ROLE.NEW_ROLE_BUTTON' | translate }}</span>
            </button>
        </ng-container>
    </kt-portlet-header>-->
    <kt-portlet-body>
        <div class="kt-form kt-margin-b-30">
            <div class="kt-form__filtration">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput #searchInput placeholder="{{ 'GENERAL.SEARCH' | translate }}">
                            <mat-hint align="start">
                                {{ 'GENERAL.SEARCH' | translate }}
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="mat-table__wrapper">
            <mat-table class="lmat-elevation-z8"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="loginDate"
                       matSortDirection="desc"
                       matSortDisableClear>

                <ng-container matColumnDef="ipaddress">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USER_MANAGEMENT.DEVICE.IPADDRESS' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let userdevice">{{userdevice.ipaddress}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="loginDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USER_MANAGEMENT.DEVICE.LOGIN_DATE' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let userdevice">{{userdevice.loginDate | date: 'HH:mm dd/MM/yyyy'}}</mat-cell>
                </ng-container>

                <!--<ng-container matColumnDef="uniqueKey">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USER_MANAGEMENT.DEVICE.UNIQUE_KEY' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let userdevice">{{userdevice.uniqueKey | slice:-20}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{'GENERAL.STATUS'|translate}}</mat-header-cell>
                    <mat-cell *matCellDef="let userdevice">
                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--{{ getItemCssClassByStatus(userdevice.isActive) }} kt-badge--wide">{{  GetStatusLabel(userdevice.isActive) }}</span>
                    </mat-cell>
                </ng-container>-->

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.ACTION' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let userdevice">
                        <button mat-icon-button color="warn"
                                matTooltip="{{ 'USER_MANAGEMENT.DEVICE.LOGOUT' | translate }}"
                                (click)="disconnectDevice(userdevice)">
                            <mat-icon>{{ 'exit_to_app' }}</mat-icon>
                        </button>
                        <!--<button mat-icon-button color="warn"
                    matTooltip="{{ 'GENERAL.DELETE' | translate }}"
                    type="button"
                    (click)="deleteObject(userdevice)">
                <mat-icon>delete</mat-icon>
            </button>-->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <div class="mat-table__message" *ngIf="!dataSource.hasItems && !(dataSource.isPreloadTextViewed$ | async)">{{'GENERAL.NO_RECORD'|translate}}</div><!-- Message for empty data  -->
            <div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">{{'GENERAL.LOADING'|translate}}</div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table__bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
</kt-portlet>

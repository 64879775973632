<ng-template [ngxPermissionsOnly]="[canRequestEdit,canApproveEdit]">
    <ng-container *ngIf="hasPendingRequest;then content_pending_request else other_content">
    </ng-container>
</ng-template>
<ng-template [ngxPermissionsOnly]="[canApproveEdit]" [ngxPermissionsExcept]="[canRequestEdit]">
    <ng-container *ngIf="hasPendingRequest;then content_pending_request else content_history">
    </ng-container>
</ng-template>
<ng-template [ngxPermissionsOnly]="[canRequestEdit]" [ngxPermissionsExcept]="[canApproveEdit]">
    <ng-container *ngIf="hasHistory;then content_history else content_edit">
    </ng-container>
</ng-template>
<ng-template #other_content>
    <ng-container *ngIf="hasHistory;then content_history else content_edit"></ng-container>
</ng-template>

<ng-template #content_pending_request>
    <button mat-icon-button class="request-edit-btn d-none mat-button-action mat-icon-button--sm text-danger" [matMenuTriggerFor]="menu" matTooltip="{{'NAME_OF_ACTIONLOG.EditRequest'|translate}}">
        <mat-icon>check_circle_outline</mat-icon>
    </button>
</ng-template>
<ng-template #content_history>
    <button mat-icon-button class="request-edit-btn d-none mat-button-action mat-icon-button--sm" color="grey" [matMenuTriggerFor]="menu" matTooltip="{{'NAME_OF_ACTIONLOG.EditRequest'|translate}}">
        <mat-icon>history</mat-icon>
    </button>
</ng-template>
<ng-template #content_edit>
    <button mat-icon-button class="request-edit-btn d-none mat-button-action mat-icon-button--sm" color="grey" matTooltip="{{'NAME_OF_ACTIONLOG.EditRequest'|translate}}" (click)="showEditRequest()">
        <mat-icon>create</mat-icon>
    </button>
</ng-template>

<mat-menu #menu="matMenu">
    <ng-template [ngxPermissionsOnly]="[canRequestEdit]">
        <button mat-menu-item (click)="showEditRequest()" class="mt-menu-item">
            <mat-icon>create</mat-icon>
            <span>{{ 'NAME_OF_ACTIONLOG.EditRequest' | translate }}</span>
        </button>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="[canRequestEdit,canApproveEdit]">
        <button mat-menu-item type="button" (click)="showEditRequestHistory()" class="mt-menu-item" *ngIf="hasHistory">
            <mat-icon>history</mat-icon>
            <span>{{ 'GENERAL.APPROVAL_LIST' | translate }}</span>
        </button>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="[canApproveEdit]">
        <button mat-menu-item type="button" (click)="showEditRequestList()" class="mt-menu-item mat-icon-button--sm" *ngIf="hasPendingRequest">
            <mat-icon>check_circle_outline</mat-icon>
            <span>{{ 'NAME_OF_ACTIONLOG.Approve' | translate }}</span>
        </button>
    </ng-template>
</mat-menu>

<div ngbDropdown [placement]="placement" class="dropdown" style="max-height: 800px; font-weight:normal" (openChange)="dropdownOpenChange($event)">
    <a ngbDropdownToggle href="javascript:;" class="mat-icon-button--label dropdown-toggle  {{ colorStyle }}" style="padding:7px;">
        <ng-container>
            <mat-icon *ngIf="iconPos=='left'">{{ icon }}</mat-icon>
            <span [innerHTML]="getSelectedLabel()"></span>
            <mat-icon *ngIf="iconPos=='right'">{{ icon }}</mat-icon>
        </ng-container>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
        <div class="m-3">
            <div class="kt-padding-b-8">
                {{ 'ORDER_TO_CASH.BOOKING.WAITING_TIME_FILTER_LABEL' | translate }}
            </div>
            <div class="kt-padding-b-16 ">
                <div class="row">
                    <input type="text"
                           class="form-control col-5 mr-2" style="border: 1px solid #d3d3d9;"
                           placeholder="60" mask="separator"
                           thousandSeparator="{{'GENERAL.THOUSAND_SEPARATOR'|translate}}"
                           [(ngModel)]="remainTime">
                    <select class="form-control custom-select col-5" style="border: 1px solid #d3d3d9;"
                            [(ngModel)]="remainTimeUnit">
                        <option *ngFor="let option of timeUnitList" [value]="option.Value">
                            {{option.Text}}
                        </option>
                    </select>
                </div>
                <div class="row mt-2 mb-2">
                    <button class="mr-2" mat-stroked-button (click)="dropdown.toggle()" [hidden]="hideCancel">
                        <span>{{ 'GENERAL.CANCEL' | translate }}</span>
                    </button>
                    <button mat-stroked-button color="primary" (click)="applyFilter(true)">
                        <span>{{ 'GENERAL.APPLY' | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="kt-padding-b-8">
                {{ 'ORDER_TO_CASH.BOOKING.WAITING_TIME_FILTER_LABEL_QUICK' | translate }}
            </div>
            <div class="kt-padding-b-16" *ngFor="let option of timeOptionList">
                <a href="javascript:;" class="kt-link" (click)="selectOption(option)">
                    {{ 'ORDER_TO_CASH.BOOKING.WAITING_TIME_FILTER_LESS_THAN' | translate }} {{option}} {{ 'ORDER_TO_CASH.BOOKING.WAITING_TIME_HOUR' | translate }}
                </a>
            </div>
            <div class="kt-padding-b-8">
                <a href="javascript:;" class="kt-link text-danger" (click)="cancelFilter()" [hidden]="selectedTime == -1">
                    {{ 'GENERAL.CANCEL_FILTER' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>

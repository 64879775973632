<ng-container [ngSwitch]="inputType">
    <span *ngSwitchDefault>{{ value }}</span>
    <span *ngSwitchCase="editRequestType.PaymentDate">{{value | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
    <span *ngSwitchCase="editRequestType.DateTime">{{value | date: 'HH:mm dd/MM/yyyy'}}</span>
    <span *ngSwitchCase="editRequestType.Time">{{value | date: 'HH:mm'}}</span>
    <span *ngSwitchCase="editRequestType.Date">{{value | date: 'dd/MM/yyyy'}}</span>
    <span *ngSwitchCase="editRequestType.Money">{{value | number}}</span>
    <span *ngSwitchCase="editRequestType.Number">{{value | number}}</span>
    <span *ngSwitchCase="editRequestType.CheckBox" class="kt-checkbox-single">
        <label class="kt-checkbox">
            <input type="checkbox" [checked]="checked" onclick="return false;">
            <span></span>
        </label>
    </span>
    <span *ngSwitchCase="editRequestType.PaymentType">
        {{getPaymentTypeLabel(value)}}
    </span>
</ng-container>

<div ngbDropdown [placement]="placement" class="dropdown" (openChange)="dropdownOpenChange($event)">
    <a ngbDropdownToggle href="javascript:;" class="mat-icon-button--label dropdown-toggle {{ colorStyle }}">
        <ng-container *ngIf="iconPos=='left'">
            <mat-icon>{{ icon }}</mat-icon><span class="dropdown-label" title="{{ getSelectedLabel() }}">{{ getSelectedLabel() }}</span>
        </ng-container>
        <ng-container *ngIf="iconPos=='right'">
            <span [ngStyle]="{'overflow': labelToggle ? 'visible' : 'hidden', 'max-width': labelToggle ? 'unset' : '94px'}"
                  class="dropdown-label" title="{{ getSelectedLabel() }}">{{ getSelectedLabel() }}</span><mat-icon>{{ icon }}</mat-icon>
        </ng-container>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
        <ng-container *ngIf="mtModel.length >= 10">
            <div class="kt-input-icon kt-input-icon--right">
                <input matInput #filterText type="text" class="form-control" placeholder="{{ 'GENERAL.SEARCH' | translate }}" autocomplete="off">
                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                    <span *ngIf="filterText.value.length==0" class="material-icons">search</span>
                    <span *ngIf="filterText.value.length>0" class="material-icons" (click)="filterText.value=''; clearFilter();" style="cursor: pointer;">clear</span>
                </span>
            </div>
            <ng-container *ngIf="mtModel.length > 0">
                <mat-selection-list [class]="'kt-nav' + (multiple ? '' : ' single-select')" [multiple]="multiple" [(ngModel)]="focusValue">
                    <mat-list-option [class]="filter.Class?filter.Class:''" checkboxPosition="before" (click)="onItemClick(filter.Value)"
                                     [value]="filter.Value"
                                     *ngFor="let filter of (mtModel | filterText:filterText.value)">
                        <span title="{{ filter.Text }}" [class]="filter.Class?filter.Class:''">{{ filter.Text }}</span>
                    </mat-list-option>
                </mat-selection-list>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="mtModel.length < 10">
            <mat-selection-list [class]="'kt-nav' + (multiple ? '' : ' single-select')" [multiple]="multiple" [(ngModel)]="focusValue">
                <mat-list-option [class]="filter.Class?filter.Class:''" checkboxPosition="before" (click)="onItemClick(filter.Value)"
                                 [value]="filter.Value"
                                 *ngFor="let filter of mtModel">
                    <span title="{{ filter.Text }}" [class]="filter.Class?filter.Class:''">{{ filter.Text }}</span>
                </mat-list-option>
            </mat-selection-list>
        </ng-container>
        <ng-container *ngIf="mtModel.length == 0">
            <span class="mat-list-text ml-3 mb-3">
                {{ waitingMsg }}
            </span>
        </ng-container>
    </div>
</div>
